export const handlePageviewBeacon = () => {
    if ( window.hasOwnProperty( '_satellite' ) ) {
        const dataObject = {};
        const _satellite = window._satellite;

        window.s.pageName = document.title;
        dataObject.pageName = document.title;
        window.s.prop5 = document.documentElement.lang === 'en' ? 'eng' : 'spa';
        dataObject.prop5 = document.documentElement.lang === 'en' ? 'eng' : 'spa';
        window.s.prop8 = 'Web Page';
        dataObject.prop8 = 'Web Page';

        window.s.pageURL = window.location.href;
        dataObject.pageURL = window.location.href;

        window.s.prop1  = window.location.href;
        dataObject.prop1  = window.location.href;

        window.s.prop31 = window.location.href;;
        dataObject.prop31 = window.location.href;

        window.s.prop55 = window.location.href;
        dataObject.prop55 = window.location.href;

        window.s.prop73 = window.location.href;
        dataObject.prop73 = window.location.href;

        _satellite.track( 'pageview', dataObject );
    }
};
